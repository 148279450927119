@import "colors.scss";
@import "fonts.scss";
@import "bootstrap/scss/functions";

// Customize Bootstrap variables before importing the rest of Bootstrap
$card-spacer-x: 1.5rem;
$card-spacer-y: 1.5rem;

.btn-outline-primary {
  &.btn-dark {
    color: $color--dark !important;
    border-color: $color--dark !important;
    background-color: transparent !important;
    font-family: "Roboto Condensed", sans-serif !important;
    font-weight: 700 !important;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    &:hover,
    &:focus,
    &:active {
      color: $color--white !important;
      border-color: $color--dark !important;
      background-color: $color--dark !important;
    }

    &.active {
      color: $color--white !important;
      background-color: $color--dark !important;
      border-color: $color--dark !important;
    }
  }
}

.badge {
  background-color: $color--ligth--grey !important;
  color: $color--dark !important;
}
