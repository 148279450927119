@import "colors.scss";

.badge {
  background-color: $badge--default--color !important;
  color: $color--black !important;
}

.badge-success {
  background-color: $badge--success--color !important;
  color: $color--black !important;
}

.badge-warning {
  background-color: $badge--warning--color !important;
  color: $color--black !important;
}

.badge-danger {
  background-color: $badge--danger--color !important;
  color: $color--black !important;
}
