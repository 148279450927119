$color--black: #000;
$color--white: #fff;

$badge--default--color: #e9ecef;
$badge--warning--color: #ffc105;
$badge--danger--color: #b6000d;
$badge--success--color: #b0c800;

$color--dark: #1d2021;
$color--ligth: #ffffff;
$color--ligth--grey: #e9ecef;
$color--grey: #4c5356;
$color--primary: #0779b5;
$color--secondary: #f4f4f4;
$color--danger: #dc3545;
$color--modal-header: #f1f3f5;
$color--border: #dadde0;
$color--active: #12a5c9;
$color--secondary-background: #edf0f2;
$color--box-shadow: #e8e8e8;
$color--invalid: #ff0000;
$color--dialog-header: #343a40;
$footer--color: $color--black;
$header--color: $color--black;
$titlebar--color: $color--dark;

$label--color: #595959;

$color-error: #f4d9db;
$color-warning: #fff3cd;
$color-success: #f2f6d4;
$color-info: #e6f0f5;

:root {
  --charts-chart-1: #7fb069;
  --charts-chart-2: #00a896;
  --charts-chart-3: #33658a;
  --charts-chart-4: #1b94b1;
  --charts-chart-5: #a29c2f;
  --charts-chart-6: #ebc43d;
  --charts-chart-7: #f6ae2d;
  --charts-chart-8: #f26419;
  --action-action-secondary-default: #000;
  --action-action-secondary-hover: #4c5356;
  --action-action-secondary-state: #a8adb3;
  --action-action-tertiary-default: #e3e6e9;
  --action-action-tertiary-hover: #dadde0;
  --action-action-tertiary-state: #edf0f2;
}
