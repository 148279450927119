// Headlines
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700 !important;
  line-height: 100%;
}

h1 {
  font-size: 5rem;
}
h2 {
  font-size: 3.5rem;
}
h3 {
  font-size: 3rem;
}
h4 {
  font-size: 2rem;
  letter-spacing: 0.32px;
  line-height: 125%;
}
h5 {
  font-size: 1.5rem;
  line-height: 133%;
}
h6 {
  font-size: 1rem;
}
