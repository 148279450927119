@import "colors.scss";

@mixin set-background($color) {
  &,
  & td {
    background-color: $color !important;
  }
}

.background-error {
  @include set-background($color-error);
}

.background-warning {
  @include set-background($color-warning);
}

.background-success {
  @include set-background($color-success);
}

.background-info {
  @include set-background($color-info);
}
