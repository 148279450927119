/* -------------------------------------------------------------------------- */
/*                           Font Embedding MyArvato                          */
/* -------------------------------------------------------------------------- */

/* ------------------------ Open Sans - Latin Extended ------------------------ */

/* open-sans-300 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src:
    url("../../src/assets/fonts/open-sans-v35-latin_latin-ext-300.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-300.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-300italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-300italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-300italic.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-regular.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-regular.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-italic.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-500 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-500.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-500.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-500italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-500italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-500italic.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-600 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-600.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-600.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-600italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-600italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-600italic.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-700.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-700italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-700italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-700italic.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-800 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-800.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-800.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-800italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src:
    url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-800italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//open-sans-v35-latin_latin-ext-800italic.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* -------------------------- Roboto - Latin Extended ------------------------- */

/* roboto-100 (Thin)- latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src:
    url("../../src/assets/fonts//roboto-v30-latin_latin-ext-100.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//roboto-v30-latin_latin-ext-100.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-300 (Light) - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src:
    url("../../src/assets/fonts//roboto-v30-latin_latin-ext-300.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//roboto-v30-latin_latin-ext-300.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-regular (Regular) - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src:
    url("../../src/assets/fonts//roboto-v30-latin_latin-ext-regular.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//roboto-v30-latin_latin-ext-regular.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-500 (Medium) - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src:
    url("../../src/assets/fonts//roboto-v30-latin_latin-ext-500.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//roboto-v30-latin_latin-ext-500.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-700 (Bold) - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src:
    url("../../src/assets/fonts//roboto-v30-latin_latin-ext-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//roboto-v30-latin_latin-ext-700.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-900 (Black) - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src:
    url("../../src/assets/fonts//roboto-v30-latin_latin-ext-900.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url("../../src/assets/fonts//roboto-v30-latin_latin-ext-900.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* -------------------- Roboto Condensed - Latin Extended ------------------- */

/* roboto-condensed-300 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  src:
    url("../../src/assets/fonts//roboto-condensed-v25-latin_latin-ext-300.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("../../src/assets/fonts//roboto-condensed-v25-latin_latin-ext-300.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-condensed-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src:
    url("../../src/assets/fonts//roboto-condensed-v25-latin_latin-ext-regular.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("../../src/assets/fonts//roboto-condensed-v25-latin_latin-ext-regular.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-condensed-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src:
    url("../../src/assets/fonts//roboto-condensed-v25-latin_latin-ext-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("../../src/assets/fonts//roboto-condensed-v25-latin_latin-ext-700.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
