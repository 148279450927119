@import "colors.scss";

// Overriding most prime ng elements
.p-element {
  &:active,
  &:focus,
  &:hover {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: none !important;
  }
}

// Overriding the input elements
.p-inputtext {
  border-radius: 0 !important;

  &:active,
  &:focus,
  &:hover {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: 0 0 0 0.2rem $color--border !important;
    border-color: $color--active !important;
  }
}

// Overriding the dropdown elements
.p-dropdown {
  border-radius: 0 !important;

  &:active,
  &:focus,
  &:hover {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: 0 0 0 0.2rem $color--border !important;
    border-color: $color--active !important;
  }

  & .p-focus {
    box-shadow: unset !important;
  }

  & > .p-inputtext {
    &:active,
    &:focus,
    &:hover {
      outline: 0 none !important;
      outline-offset: 0 !important;
      box-shadow: none !important;
    }
  }
}

.p-multiselect {
  border-radius: 0 !important;

  &:active,
  &:focus,
  &:hover {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: 0 0 0 0.2rem $color--border !important;
    border-color: $color--active !important;
  }

  & .p-focus {
    box-shadow: unset !important;
  }

  & > .p-inputtext {
    &:active,
    &:focus,
    &:hover {
      outline: 0 none !important;
      outline-offset: 0 !important;
      box-shadow: none !important;
    }
  }
}

.p-multiselect-items {
  margin: 0 !important;
  padding: 0 !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: transparent !important;
  border-color: $color--dark !important;
  color: $color--dark !important;
}

.p-checkbox-icon {
  color: $color--dark !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $color--dark !important;
}

.p-dropdown-items {
  padding-left: 0 !important;
}

// Overriding the focus behaviour for some elements
.p-focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem $color--border !important;
  border-color: $color--active !important;
}

// Overriding the highlight elements (selected dropdown elements)
.p-highlight {
  color: $color--dark !important;
  background: $color--secondary !important;
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}

// Overriding the button elements (Calander)
button.p-link {
  &:active,
  &:focus,
  &:hover {
    color: inherit !important;
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: 0 0 0 0.2rem $color--border !important;
    border-color: $color--active !important;
  }
}

.p-message-close {
  margin-left: auto;
}

.p-datepicker-other-month {
  color: transparent !important;
}
