// Overwrite default container size
$container-max-widths: (
  sm: 720px,
  md: 960px,
  lg: 1140px,
  xl: 1320px,
  xxl: 1680px,
);

$page-title-size: 2.5rem;
$page-subtitle-size: 1.5rem;
